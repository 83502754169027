// Dashboard.js
import React from "react";

const Dashboard = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-200">
            <h1 className="text-3xl font-bold">Welcome to the Dashboard!</h1>
        </div>
    );
};

export default Dashboard;
