import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../home/Home";
import { ProtectedRoute, ProtectedRouteHome } from "./ProtectedRoute";
import Dashboard from "../components/Dashboard";

export const RoutesList: FC = () => {
  return (
    <>
      <main>
            <Routes>
                  <Route
                      path='/homepage'
                      element={
                              <Home />
                      }
                  />
                  <Route
                      path='/'
                      element={
                          <ProtectedRouteHome >
                              <Dashboard />
                          </ProtectedRouteHome>
                     
                      }
                  />
          <Route
            path='/create'
            element={
              <ProtectedRoute>
                    <Home />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
    </>
  );
};
