import images from "../assets/images"
const Howitworks = () => 
   (
    <section className='grid  grid-rows-1 grid-cols-1  sm:grid-cols-2 gap-5 mt-10 px-12'>
<div className='max-w-[600px] mb-8'>
  <img src={images.IllustrationSproductive} alt="stay productive" className='object-contain w-full' />

</div>

<div className='text-white mt-8 sm:mt-[6rem]'>

<h2 className=' text-2xl sm:text-4xl mb-8'>Tranform and validate the data whenever ,wherever</h2>
<p className='text-white/80 leading-7 text-[16px] font-opensans mb-4'>
                A system that compares and matches data across multiple sources to identify discrepancies and ensure accuracy in financial or transactional records.
<br></br>
<br></br>
                A process that extracts data from various sources, transforms it into a usable format, and loads it into a target system for analysis or storage.
</p>

<a href="#" className='text-[15px]  border-b-[1px] border-secondary-cyangradient text-secondary-cyangradient pb-1'>
<span>See how Refine-Point works</span><img src={images.ArrowIcon} alt="arrow"  className='inline ml-1 w-[20px]'/>
</a>

</div>


    </section>
  )


export default Howitworks