import { FC, useState } from "react";
import logo from '../assets/logo.svg';
import { signInWithPopup, signOut } from "firebase/auth";
import { auth, provider } from "../firebase/config";
import { getLocalStorageItem, setLocalStorageItem } from "../utils/Utils";
import { useNavigate } from "react-router-dom";

export const Header: FC = () => {
    const [isAuth, setIsAuth] = useState<boolean>(getLocalStorageItem("isAuth") ?? false);
    const navigate = useNavigate(); // Initialize the navigate function

    const handleLogin = () => {
        signInWithPopup(auth, provider)
            .then(() => {
                setIsAuth(true);
                setLocalStorageItem<boolean>("isAuth", true);

            })
            .catch((error) => console.log(error));
    };

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                setIsAuth(false);
                localStorage.clear();
                navigate('/homepage'); // Redirect to /homepage
            })
            .catch((error) => console.log(error));
    };

    return (
        <>
            <nav className='w-full flex flex-col md:flex-row md:items-center text-white navbar px-4 py-4 md:px-16 md:py-8'>
                <img src={logo} alt="logo" className="w-[190px] h-[60px] mb-4 md:mb-0 md:mr-8" />
                <ul className='list-none flex flex-col md:flex-row justify-start md:justify-end items-start md:items-center w-full'>
                    <li className='font-raleway mb-4 md:mb-0 md:mr-6'>
                        <a href="#features" className='text-[#bdc0ef] hover:underline hover:text-white'>Features</a>
                    </li>
                    <li className='font-raleway mb-4 md:mb-0 md:mr-6'>
                        <a href="#pricing" className='text-[#bdc0ef] hover:underline hover:text-white'>Pricing</a>
                    </li>
                    <li className='font-raleway mb-4 md:mb-0 md:mr-6'>
                        <a href="#blog" className='text-[#bdc0ef] hover:underline hover:text-white'>Blog</a>
                    </li>
                    <li className='font-raleway mb-4 md:mb-0 md:mr-6'>
                        <a href="#about" className='text-[#bdc0ef] hover:underline hover:text-white'>About</a>
                    </li>
                    {isAuth ? (
                        <li className='font-raleway mb-4 md:mb-0'>
                            <a onClick={handleLogout} className='cursor-pointer text-[#bdc0ef] hover:underline hover:text-white'>
                                <i className='bi bi-box-arrow-right'></i> Logout
                            </a>
                        </li>
                    ) : (
                        <li className='font-raleway mb-4 md:mb-0'>
                            <a onClick={handleLogin} className='cursor-pointer text-[#bdc0ef] hover:underline hover:text-white'>
                                <i className='bi bi-google'></i> Login
                            </a>
                        </li>
                    )}
                </ul>
            </nav>
        </>
    );
};
