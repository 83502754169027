import { FC } from "react";

import { useTitle } from "../utils/useTitle";

import {Hero, Feature, Howitworks, Testimonial, SubscriptionForm } from '../components/index';

export const Home: FC = () => {
  useTitle("Home");
  return (
    <>
         <div className='w-full bg-primary-hero-bluecolor'>

              <Hero />
              <div className='bg-primary-mainbody-bluecolor'>
                  <div className=' px-4 py-4 md:px-16 md:py-8'>
                      <Feature />
                      <Howitworks />
                      <Testimonial />
                      <SubscriptionForm />
                  </div>

              </div>
          </div>
    </>
  );
};
