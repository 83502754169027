import images from '../assets/images'

import {faFacebookF, faInstagram,faTwitter} from '@fortawesome/free-brands-svg-icons'

export const navLinks = [
  
    
  {
    id: "features",
    title: "Features",
  },
  {
    id: "Team",
    title: "Team",
  },
  {
    id: "Sign In",
    title: "Sign In",
  },
];

export const features = [
  {
    id: "feature-1",
    icon: images.AccessIcon,
    title: "Process Files, No Boundaries ",
    content:
      "Handle files in any format, from anywhere, anytime, with complete flexibility.",
  },
  {
    id: "feature-2",
    icon: images.Iconsecurity,
    title: "Security you can trust",
    content:
      "Protect your data with robust security measures, ensuring trust and peace of mind.",
  },
  {
    id: "feature-3",
    icon: images.CollabrationIcon,
    title: "Real-time collaboration",
    content:
      "Tranformation, Reconcilation,Reporting all at one place...",
  },
  {
    id: "feature-4",
    icon: images.AnyfileIcon,
    title: "Process any type of file",
    content:
      "Whether you're sharing csv , excel, mt or mx,  Refine Point has you covered allowing for all file types to be securely process.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "RefinePoint has improved our team productivity by an order of magnitude. Since making the  switch our team has become a well-oiled collaboration machine.",
    name: "Satish Patel",
    title: "Founder & CEO, Huddle",
    img: images.Profile1,
  },
  {
    id: "feedback-2",
    content:
      "RefinePoint  has improved our team productivity by an order of magnitude. Since making the  switch our team has become a well-oiled collaboration machine.",
    name: "Bruce McKenzie",
    title: "Founder & CEO, Huddle",
    img: images.Profile2,
  },
  {
    id: "feedback-3",
    content:
    "RefinePoint  has improved our team productivity by an order of magnitude. Since making the  switch our team has become a well-oiled collaboration machine.",
    name: "Iva Boyd",
    title: "Founder & CEO, Huddle",
    img: images.Profile3,
  },
];

export const contactInfo = [
  {
    id: "Phone",
    icon: images.iconPhone,
    link: "#",
    text:"+1-543-123-4567",
  },
  {
    id: "Email",
    icon: images.iconEmail,
    link: "#",
    text:" example@fylo.com",
  },
 ]




export const footerLinks = [
    {
        links: [
      {
        name: "About Us",
        link: "#",
      },
      {
        name: "Jobs",
        link: "#",
      },
      {
        name: "Press",
        link: "#",
      },
      {
        name: "Blog",
        link: "#",
      },
          ],
  },
  {
       links: [
      {
        name: "Contact Us",
        link: "#",
      },
      {
        name: "Terms",
        link: "#",
      },
      {
        name: "Privacy",
        link: "#",
      },
       ],
  },
  ];


  export const socialMedia = [
    {
      id: "facebook",
     img: faFacebookF,
    },
    {
      id: "twitter",
   img:faTwitter,
    },
    {
      id: "intsgram",
      img: faInstagram,
    },
  ];
  
