
const images = {
  
    AccessIcon : new URL('./AccessIcon.svg', import.meta.url).href,
    AnyfileIcon : new URL('./AnyfileIcon.svg', import.meta.url).href,
    ArrowIcon : new URL('./ArrowIcon.svg', import.meta.url).href,
    BackgroundCurveDesktop : new URL('./BackgroundCurveDesktop.svg', import.meta.url).href,
    BackgroundCurveMobile : new URL('./BackgroundCurveMobile.svg', import.meta.url).href,
    BgQuote : new URL('./BgQuote.png', import.meta.url).href,
    CollabrationIcon : new URL('./CollabrationIcon.svg', import.meta.url).href,
    iconEmail : new URL('./iconEmail.svg', import.meta.url).href,
    iconLocation : new URL('./iconLocation.svg', import.meta.url).href,
    iconPhone : new URL('./iconphone.svg', import.meta.url).href,
    logo : new URL('./logo.svg', import.meta.url).href,
    Profile1 : new URL('./Profile1.jpg', import.meta.url).href,
    Profile2 : new URL('./Profile2.jpg', import.meta.url).href,
    Profile3 : new URL('./Profile3.jpg', import.meta.url).href,
    IllustrationIntro : new URL('./IllustrationIntro.png', import.meta.url).href,
    IllustrationSproductive : new URL('./IllustrationSproductive.png', import.meta.url).href,
    Iconsecurity : new URL('./Iconsecurity.svg', import.meta.url).href,
  
}

export default images