// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { CollectionReference, DocumentData, collection, getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// Your web app's Firebase configuration using environment variables
const firebaseConfig = {
  apiKey: "AIzaSyDK7dOy2xj92LEcljLS_IOBRPQcm9u9GQo",
  authDomain: "refinepoint.com",
  databaseURL: "https://token-registration-84a2b-default-rtdb.firebaseio.com",
  projectId: "token-registration-84a2b",
  storageBucket: "token-registration-84a2b.firebasestorage.app",
  messagingSenderId: "855454185901",
 appId: "1:855454185901:web:b23bd571e73a4ef8d78924",
    measurementId: "G-LZ462C2QXS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Export Firebase services
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const analytics = getAnalytics(app);

// Create a collection reference utility function
export const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(db, collectionName) as CollectionReference<T>;
};
