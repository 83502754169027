import { Header } from './components/Header';

import { Footer } from './components/index';
import { RoutesList } from "./routes/RoutesList";

const App = () => (
<div className='w-full bg-primary-hero-bluecolor'>
 <Header />
   <RoutesList />
 <Footer />
</div>
  )


export default App